export const navsHeader = [
  // {
  //   name: "Oriental",
  //   url: "/oriental",
  // },
  {
    name: "Eastern Europe",
    url: "/easternEurope",
  },
  {
    name: "Far East",
    url: "/farEast",
  },
  {
    name: "South America",
    url: "/southAmerica",
  },
  {
    name: "Europe",
    url: "/europe",
  },
];
