import React from "react";
import { useWindowSize } from "../../hooks";
import { offers as offersData, findOffer } from "../../data";
import { Offer } from "./Offer";

import styles from "./TopOffersTiles.module.scss";

export const TopOffersTiles = ({ title = "", trio = false }) => {
  const size = useWindowSize();

  const firstOffer = { ...findOffer("AsianMelodies"), id: 1 };
  const secondOffer = { ...findOffer("OrchidRomance"), id: 2 };
  const thirdOffer = { ...findOffer("LaDate"), id: 3 };

  const offers = trio
    ? size.width <= 768
      ? [firstOffer, secondOffer, thirdOffer]
      : [secondOffer, firstOffer, thirdOffer]
    : offersData;

  return (
    <div className={`${styles.TopOffersTiles} ${trio ? styles.TopOffersTilesTrio : ""} `}>
      {title !== "" && (
        <h2 className={`title-section ${trio ? styles.TitleTrio : ""}`}>{title}</h2>
      )}
      <div className={styles.Row}>
        {offers.map((offer, index) => (
          <div key={index} className={styles.Col}>
            <Offer index={index} {...offer} />
          </div>
        ))}
      </div>
    </div>
  );
};
