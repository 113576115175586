export const easternEurope = [
  {
    name: "Olena",
    age: 21,
    location: "Ukraine, Kharkiv",
  },
  {
    name: "Alla",
    age: 20,
    location: "Ukraine, Kyiv",
  },
  {
    name: "Veronika",
    age: 24,
    location: "Ukraine, Odesa",
  },
  {
    name: "Yuliya",
    age: 30,
    location: "Ukraine, Lviv",
  },
  {
    name: "Oleksandra",
    age: 34,
    location: "Ukraine, Kyiv",
  },
  {
    name: "Zoya",
    age: 29,
    location: "Ukraine, Kharkiv",
  },
  {
    name: "Lyudmyla",
    age: 37,
    location: "Ukraine, Dnipro",
  },
];
