import React from "react";

import { PATH_LOGO, PATH_REVIEWS, PATH_REDIRECT } from "../configs";

const CuteAsianWoman = React.lazy(() => import("../pages/reviews/cuteasianwoman"));
const DateRussianGirl = React.lazy(() => import("../pages/reviews/daterussiangirl"));
const KissRussianBeauty = React.lazy(() => import("../pages/reviews/kissrussianbeauty"));
const AsianMelodies = React.lazy(() => import("../pages/reviews/asianmelodies"));
const EasternHoneys = React.lazy(() => import("../pages/reviews/easternhoneys"));
const DateAsianWoman = React.lazy(() => import("../pages/reviews/dateasianwoman"));
const LoveFort = React.lazy(() => import("../pages/reviews/lovefort"));
const LaDate = React.lazy(() => import("../pages/reviews/ladate"));
const LatinFeels = React.lazy(() => import("../pages/reviews/latinfeels"));
const LatinWomanLove = React.lazy(() => import("../pages/reviews/latinwomanlove"));
const DateUkrainianGirl = React.lazy(() => import("../pages/reviews/dateukrainiangirl"));
const UkrainianCharm = React.lazy(() => import("../pages/reviews/ukrainiancharm"));
const MeetUkrainianGirl = React.lazy(() => import("../pages/reviews/meetukrainiangirl"));
const AmourFactory = React.lazy(() => import("../pages/reviews/amourfactory"));
const LoverWhirl = React.lazy(() => import("../pages/reviews/loverwhirl"));
const AsiaBeautyDate = React.lazy(() => import("../pages/reviews/asiabeautydate"));
const AsianDate = React.lazy(() => import("../pages/reviews/asiandate"));
const ColombiaLady = React.lazy(() => import("../pages/reviews/colombialady"));
const LatinBeautyDate = React.lazy(() => import("../pages/reviews/latinbeautydate"));
const DateRussianWoman = React.lazy(() => import("../pages/reviews/daterussianwoman"));
const SingleRussianBeauty = React.lazy(() => import("../pages/reviews/singlerussianbeauty"));
const RussianBeautyDate = React.lazy(() => import("../pages/reviews/russianbeautydate"));
const RomanceTicket = React.lazy(() => import("../pages/reviews/romanceticket"));
const AsianBeautyOnline = React.lazy(() => import("../pages/reviews/asianbeautyonline"));
const Hotukrainians = React.lazy(() => import("../pages/reviews/hotukrainians"));
const AsiaMe = React.lazy(() => import("../pages/reviews/asiame"));
const OrchidRomance = React.lazy(() => import("../pages/reviews/orchidromance"));
const EuropeanDate = React.lazy(() => import("../pages/reviews/europeandate"));
const Meeteuropeanbeauty = React.lazy(() => import("../pages/reviews/meeteuropeanbeauty"));
const BravoDate = React.lazy(() => import("../pages/reviews/bravodate"));

export const offers = [
  // {
  //   name: "AsiDates",
  //   rating: 9.7,
  //   logo: PATH_LOGO + "asidates.svg",
  //   description: "Find your true love with Asian beauty",
  //   bonus: "Test for free",
  //   review: PATH_REVIEWS + "/asidates",
  //   link: "asidates",
  //   component: AsiDates,
  // },
  {
    name: "CuteAsianWoman",
    rating: 9.0,
    logo: PATH_LOGO + "cuteasianwoman.png",
    description: "Modern dating platform to meet Asian girl",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/cuteasianwoman",
    link: "cuteasianwoman",
    component: CuteAsianWoman,
  },
  {
    name: "DateRussianGirl",
    rating: 9.6,
    logo: PATH_LOGO + "daterussiangirl.png",
    description: "Find your true love with Russian beauty",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/daterussiangirl",
    link: "daterussiangirl",
    component: DateRussianGirl,
  },
  {
    name: "KissRussianBeauty",
    rating: 9.1,
    logo: PATH_LOGO + "kissrussianbeauty.svg",
    description: "Meet the right Slavic women for you",
    bonus: "20 Trial Credits",
    review: PATH_REVIEWS + "/kissrussianbeauty",
    link: "kissrussianbeauty",
    component: KissRussianBeauty,
  },
  {
    name: "AsianMelodies",
    rating: 9.2,
    logo: PATH_LOGO + "asianmelodies.png",
    pmaxLogo: PATH_LOGO + "pmax-asianmelodies.png",
    description: "Modern dating platform to meet Far East girl",
    bonus: "Receive Trial Bonus",
    review: PATH_REVIEWS + "/asianmelodies",
    link: "asianmelodies",
    component: AsianMelodies,
  },
  {
    name: "EasternHoneys",
    rating: 9.0,
    logo: PATH_LOGO + "easternhoneys.svg",
    description: "Build a new relationship with Far East girls",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/easternhoneys",
    link: "easternhoneys",
    component: EasternHoneys,
  },
  {
    name: "DateAsianWoman",
    rating: 8.7,
    logo: PATH_LOGO + "dateasianwoman.png",
    description: "Find your true love with Far East beauty",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/dateasianwoman",
    link: "dateasianwoman",
    component: DateAsianWoman,
  },
  {
    name: "AsiaMe",
    rating: 9.2,
    logo: PATH_LOGO + "asiame.svg",
    description: "Meet the right Far East women for you",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/asiame",
    link: "asiame",
    component: AsiaMe,
  },
  {
    name: "LoveFort",
    rating: 9.4,
    logo: PATH_LOGO + "lovefort.svg",
    description: "Find your true love with Lartin woman",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/lovefort",
    link: "lovefort",
    component: LoveFort,
  },
  {
    name: "LaDate",
    rating: 9.8,
    logo: PATH_LOGO + "la-date.svg",
    pmaxLogo: PATH_LOGO + "pmax-la-date.png",
    description: "Find your true love with South America woman",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/ladate",
    link: "ladate",
    component: LaDate,
  },
  {
    name: "LatinFeels",
    rating: 9.8,
    logo: PATH_LOGO + "latinfeels.png",
    description: "Meet the right South America women for you",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/latinfeels",
    link: "latinfeels",
    component: LatinFeels,
  },
  {
    name: "LatinWomanLove",
    rating: 9.6,
    logo: PATH_LOGO + "latinwomanlove.png",
    description: "Most popular worldwide and tons of matches with Venezuela girls",
    bonus: "Receive Trial Bonus",
    review: PATH_REVIEWS + "/latinwomanlove",
    link: "latinwomanlove",
    component: LatinWomanLove,
  },
  // {
  //   name: "LatamDate",
  //   rating: 9.2,
  //   logo: PATH_LOGO + "latamdate.svg",
  //   description: "Fun and easy dating with Latina singles",
  //   bonus: "20 Trial Credits",
  //   review: PATH_REVIEWS + "/latamdate",
  //   link: "latamdate",
  //   component: LatamDate,
  // },
  {
    name: "DateUkrainianGirl",
    rating: 9.6,
    logo: PATH_LOGO + "dateukrainiangirl.png",
    description: "Find your true love with Eastern Europe woman",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/dateukrainiangirl",
    link: "dateukrainiangirl",
    component: DateUkrainianGirl,
  },
  {
    name: "UkrainianCharm",
    rating: 9.6,
    logo: PATH_LOGO + "ukrainiancharm.svg",
    description: "Find your true love with Eastern Europe woman",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/ukrainiancharm",
    link: "ukrainiancharm",
    component: UkrainianCharm,
  },
  {
    name: "MeetUkrainianGirl",
    rating: 9.1,
    logo: PATH_LOGO + "meetukrainiangirl.png",
    description: "Most popular worldwide and tons of matches with Slavic girls",
    bonus: "Receive Trial Bonus",
    review: PATH_REVIEWS + "/meetukrainiangirl",
    link: "meetukrainiangirl",
    component: MeetUkrainianGirl,
  },
  {
    name: "AmourFactory",
    rating: 8.8,
    logo: PATH_LOGO + "amourfactory.svg",
    description: "Free Registration Only Today",
    bonus: "Receive Trial Bonus",
    review: PATH_REVIEWS + "/amourfactory",
    link: "amourfactory",
    component: AmourFactory,
  },
  {
    name: "LoverWhirl",
    rating: 8.2,
    logo: PATH_LOGO + "loverwhirl.svg",
    description: "Fun and easy dating with South America singles",
    bonus: "Receive Trial Bonus",
    review: PATH_REVIEWS + "/loverwhirl",
    link: "loverwhirl",
    component: LoverWhirl,
  },
  {
    name: "AsiaBeautyDate",
    rating: 9.0,
    logo: PATH_LOGO + "asiabeautydate.svg",
    description: "Beautiful Far East Ladies",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/asiabeautydate",
    link: "asiabeautydate",
    component: AsiaBeautyDate,
  },
  {
    name: "AsianDate",
    rating: 8.9,
    logo: PATH_LOGO + "asiandate.png",
    description: "Official App. Advanced partners search. More than 70,000 users",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/asiandate",
    link: "asiandate",
    component: AsianDate,
  },
  {
    name: "ColombiaLady",
    rating: 9.8,
    logo: PATH_LOGO + "colombialady.jpg",
    description: "Beautiful Colombian Ladies",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/colombialady",
    link: "colombialady",
    component: ColombiaLady,
  },
  {
    name: "LatinBeautyDate",
    rating: 9.6,
    logo: PATH_LOGO + "latinbeautydate.svg",
    description: "Top Site To Meet Love",
    bonus: "Free Trial Only Today",
    review: PATH_REVIEWS + "/latinbeautydate",
    link: "latinbeautydate",
    component: LatinBeautyDate,
  },
  {
    name: "DateRussianWoman",
    rating: 8.6,
    logo: PATH_LOGO + "daterussianwoman.png",
    description: "Free Registration Only Today",
    bonus: "Test For Free",
    review: PATH_REVIEWS + "/daterussianwoman",
    link: "daterussianwoman",
    component: DateRussianWoman,
  },
  {
    name: "SingleRussianBeauty",
    rating: 8.4,
    logo: PATH_LOGO + "singlerussianbeauty.png",
    description: "10000 of Eastern Europe Women Here",
    bonus: "Receive Trial Bonus",
    review: PATH_REVIEWS + "/singlerussianbeauty",
    link: "singlerussianbeauty",
    component: SingleRussianBeauty,
  },
  {
    name: "RussianBeautyDate",
    rating: 9.4,
    logo: PATH_LOGO + "russianbeautydate.png",
    description: "Best Dating Site To Find Love",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/russianbeautydate",
    link: "russianbeautydate",
    component: RussianBeautyDate,
  },
  {
    name: "Hotukrainians",
    rating: 8,
    logo: PATH_LOGO + "hotukrainians-logo.png",
    description: "Build a new relationship with girls from Russia",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/hotukrainians",
    link: "hotukrainians",
    component: Hotukrainians,
  },
  {
    name: "RomanceTicket",
    rating: 8,
    logo: PATH_LOGO + "romanceticket-logo.svg",
    description: "Most popular worldwide and tons of matches with Slavic girls",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/romanceticket",
    link: "romanceticket",
    component: RomanceTicket,
  },
  {
    name: "AsianBeautyOnline",
    rating: 8.5,
    logo: PATH_LOGO + "asianbeautyonline.png",
    description: "Most popular worldwide and tons of matches with Asian girls",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/asianbeautyonline",
    link: "asianbeautyonline",
    component: AsianBeautyOnline,
  },
  // {
  //   name: "Cupid",
  //   rating: 9.3,
  //   logo: PATH_LOGO + "cupid-logo.svg",
  //   description:
  //     "An amazing platform to meet your soulmate from Asia or any corner of the globe",
  //   bonus: "Test for free",
  //   review: PATH_REVIEWS + "/cupid",
  //   link: "cupid",
  //   component: Cupid,
  // },
  {
    name: "OrchidRomance",
    rating: 9.0,
    logo: PATH_LOGO + "orchidromance-logo.png",
    description: "High-Class Far East Women",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/orchidromance",
    link: "orchidromance",
    component: OrchidRomance,
  },
  {
    name: "EuropeanDate",
    rating: 9.5,
    logo: PATH_LOGO + "europeandate-logo.png",
    description: "Most popular worldwide and tons of matches with Europe girls",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/europeandate",
    link: "europeandate",
    component: EuropeanDate,
  },
  {
    name: "Meeteuropeanbeauty",
    rating: 9.4,
    logo: PATH_LOGO + "meeteuropeanbeauty-logo.png",
    description: "High-Class Europe Women",
    bonus: "Test for free",
    review: PATH_REVIEWS + "/meeteuropeanbeauty",
    link: "meeteuropeanbeauty",
    component: Meeteuropeanbeauty,
  },
  {
    name: "RomanceTicketAmour",
    rating: 8,
    logo: PATH_LOGO + "romanceticket-logo.svg",
    description: "Most popular worldwide and tons of matches with Slavic girls",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/romanceticket",
    link: "amourfactory",
    component: RomanceTicket,
  },
  {
    name: "BravoDate",
    rating: 8,
    logo: PATH_LOGO + "bravodate.svg",
    description: "Most popular worldwide and tons of matches with Slavic girls",
    bonus: "Try for Free",
    review: PATH_REVIEWS + "/bravoDate",
    link: "bravodate",
    component: BravoDate,
  },
].map((offer, index) => ({
  id: index + 1,
  ...offer,
}));

export const orientalOffers = [
  "AsianMelodies",
  "OrchidRomance",
  "DateAsianWoman",
  "CuteAsianWoman",
];

export const easternEuropeOffers = ["UkrainianCharm", "AmourFactory", "LoveFort", "BravoDate"];

export const asianOffers = [
  "AsianMelodies",
  "OrchidRomance",
  "EasternHoneys",
  "AsianDate",
  "AsianBeautyOnline",

  // "AsiaMe",
  // "CuteAsianWoman",
  // "AsiaBeautyDate",
  // "DateAsianWoman",
];

export const southAmericanOffers = [
  "LaDate",
  "LatinFeels",
  "LatinBeautyDate",
  "LoveFort",

  // "ColombiaLady",
  // "LatinWomanLove",
];

export const europeanOffers = ["UkrainianCharm", "AmourFactory", "LoveFort", "BravoDate"];

export const findOffer = name => offers.find(offer => offer.name === name);
