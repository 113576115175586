import React from "react";
import FadeIn from "react-fade-in";
import { Parallax } from "react-parallax";

import { TrackingLink } from "../../components";
import { Button1 } from "../../components/Buttons";
import { findOffer } from "../../data";

import image from "../../assets/images/main.webp";

import styles from "./MainScreen.module.scss";

export const MainScreen = () => {
  const offer = findOffer("DateAsianWoman");

  return (
    <Parallax strength={300} bgImage={image}>
      <div className={styles.MainScreen}>
        <div className="container">
          <FadeIn>
            <div className={styles.Title}>
              We believe in love <br />
              and make easy to find singles <br />
              right here!
            </div>
            <div className={styles.Description}>Matchadvisor is connecting hearts</div>
            <div className={styles.ButtonBlock}>
              <TrackingLink href={offer.link} component={Button1}>
                Find a lady
              </TrackingLink>
            </div>
          </FadeIn>
        </div>
      </div>
    </Parallax>
  );
};
