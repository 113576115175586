import {
  easternEurope,
  china,
  japan,
  slavic,
  farEast,
  philippine,
  southAmerica,
  europe,
} from "./girls";
import {
  easternEuropeOffers,
  asianOffers,
  southAmericanOffers,
  findOffer,
  europeanOffers,
} from "./offers";

const imagePath = nationality => `/images/girls/${nationality}/`;

const constructor = (nationality, offers, girls) =>
  girls.map((girl, index) => {
    const i = index + 1;
    const evenParity = i % 2 === 0;
    const oddOffer = field => findOffer(offers[0])[field];
    const evenOffer = field => findOffer(offers[1])[field];

    const id = `${nationality}-${i}`;
    const image = `${imagePath(nationality)}${i}.webp`;
    const website = evenParity ? oddOffer("name") : evenOffer("name");
    const url = evenParity ? oddOffer("link") : evenOffer("link");

    return {
      id,
      image,
      website,
      url,
      ...girl,
    };
  });

export const girls = [
  {
    nationality: "Japan",
    girls: constructor("japan", asianOffers, japan),
  },
  {
    nationality: "EasternEurope",
    girls: constructor("easternEurope", easternEuropeOffers, easternEurope),
  },
  {
    nationality: "China",
    girls: constructor("china", asianOffers, china),
  },
  {
    nationality: "FarEast",
    girls: constructor("farEast", asianOffers, farEast),
  },
  {
    nationality: "Philippine",
    girls: constructor("philippine", asianOffers, philippine),
  },
  {
    nationality: "SouthAmerica",
    girls: constructor("southAmerica", southAmericanOffers, southAmerica),
  },
  {
    nationality: "Europe",
    girls: constructor("europe", europeanOffers, europe),
  },
];
