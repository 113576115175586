import React from "react";
import {
  HomePageLayout,
  CategoryPageLayout,
  ReviewLayout,
  NationalityLayout,
  DefaultLayout,
  PmaxQuizPageLayout,
} from "../layouts";
import { offers, nationalities } from "../data";

const Home = React.lazy(() => import("./home"));
// const Oriental = React.lazy(() => import("./oriental"));
const EasternEurope = React.lazy(() => import("./easternEurope"));
const Asian = React.lazy(() => import("./asian"));
const SouthAmerica = React.lazy(() => import("./southAmerica"));
const Europe = React.lazy(() => import("./europe"));
const PmaxQuiz = React.lazy(() => import("./quiz/pmax"));

export const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: Home,
    layout: HomePageLayout,
  },
  // {
  //   path: "/oriental",
  //   name: "Oriental",
  //   component: Oriental,
  //   layout: CategoryPageLayout,
  // },
  {
    path: "/easternEurope",
    name: "Eastern Europe",
    component: EasternEurope,
    layout: CategoryPageLayout,
  },
  {
    path: "/farEast",
    name: "Far East",
    component: Asian,
    layout: CategoryPageLayout,
  },
  {
    path: "/southAmerica",
    name: "South America",
    component: SouthAmerica,
    layout: CategoryPageLayout,
  },
  {
    path: "/europe",
    name: "Europe",
    component: Europe,
    layout: CategoryPageLayout,
  },
  {
    path: "/quiz/pmax",
    name: "PmaxQuiz",
    component: PmaxQuiz,
    layout: PmaxQuizPageLayout,
  },
  {
    path: "*",
    name: "Home",
    component: Home,
    layout: HomePageLayout,
  },
  {
    name: "Home",
    component: Home,
    layout: HomePageLayout,
  },
  ...offers.map(({ name, review, component: Component }) => ({
    name,
    path: review,
    component: Component,
    layout: ReviewLayout,
  })),
  ...nationalities.map(({ name, review, component: Component }) => ({
    name,
    path: review,
    component: Component,
    layout: NationalityLayout,
  })),
];
