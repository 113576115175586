export const farEast = [
  {
    name: "Hui Yin",
    age: 19,
    location: "China, Shanghai",
  },
  {
    name: "Kartika",
    age: 25,
    location: "Indonesia, Jakarta",
  },
  {
    name: "BichJade",
    age: 30,
    location: "Vietnam, Ho Chi Minh City",
  },
  {
    name: "Kanda",
    age: 35,
    location: "Thailand, Bangkok",
  },
  {
    name: "Kiara",
    age: 32,
    location: "India, Hyderabad",
  },
  {
    name: "Haymar",
    age: 29,
    location: "Myanmar, Yangon",
  },
  {
    name: "Fatima",
    age: 22,
    location: "India, Surat",
  },
  {
    name: "Chia-Jung",
    age: 21,
    location: "Taiwan, New Taipei City",
  },
  {
    name: "Akari",
    age: 33,
    location: "Japan, Yokohama",
  },
  {
    name: "Chantrea",
    age: 36,
    location: "Cambodia, Phnom Penh",
  },
];
